require('intersection-observer');
import w3 from './css/w3.css';
import css from './css/style.css';

const threshold = createThreshold();
const IS_DEBUG = false;
var handlers = [];

if (IS_DEBUG)
{
  var debug = console.log.bind(window.console);
}
else
{
  var debug = function () { };
}

window.addEventListener('DOMContentLoaded', () => {
  debug("DOMContentLoaded");
  initDesktopContactForm();
  initMobileContactForm();
  setTimeout( // Wait for images to fully load
    () => {
      observe('.parallax-container', threshold, heroParallax);
    }, 200);
});

/**
 * Creates an IntersectionObserver and starts observing all elements found using the selector.
 *
 * @param {String} selector: Selector used to find all target elements
 * @param {Number[]} threshold: Array of intersection ratios, at which the callback is executed
 * @param {Function} callback: Callback executed for each threshold
 */
function observe(selector, threshold, callback)
{
  const elements = document.querySelectorAll(selector);
  const options = {
    rootMargin: '0px',
    threshold: threshold,
  };

  const observer = new IntersectionObserver(callback, options);

  for (const element of elements)
  {
    observer.observe(element);
  }
}

/**
 * Callback executed for the box elements
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * @param {IntersectionObserverEntry[]} entries: Intersection Observer Entries
 * @param {IntersectionObserver} observer: Intersection Observer
 */
function heroParallax(entries, observer)
{
  if(window.innerWidth > 768){

    entries.forEach(entry => {
/*       debug("entry target ID: " + entry.target.id);
      debug("layers:"); */
      /* debug("entry.id: " + entry.id + ", entry.target.id: " + entry.target.id); */

      var layers = document.getElementById(entry.target.id).querySelectorAll("[data-type='parallax']");
     /*  debug(layers); */
     
      var id = entry.target.id;
      window.removeEventListener('scroll', handlers[id]);
      window.removeEventListener('touchstart', handlers[id]);
      window.removeEventListener('touchend', handlers[id]);
      window.removeEventListener('touchmove', handlers[id]);
      window.removeEventListener('wheel', handlers[id]);

      if(entry.isIntersecting && entry.intersectionRatio >= .99 && isElementInViewport(entry.target, 10) && id != "footer")
      {
        debug("Element ID: " + id + " is in viewport!");
        handlers[id] = () => elementScrolled(entry, layers);
        window.addEventListener('scroll', handlers[id]);
        window.addEventListener('touchstart', handlers[id]);
        window.addEventListener('touchend', handlers[id]);
        window.addEventListener('touchmove', handlers[id]);
        window.addEventListener('wheel', handlers[id]);
        debug("Handlers: " );
        debug(handlers);
      }
      else if (entry.isIntersecting && entry.intersectionRatio)
      {
        /* debug(entry.target.id + ": is intersecting at ratio: " + entry.intersectionRatio);
        debug(entry); */
        parallaxShift(entry.boundingClientRect.top, layers, false);
      }
    });
  }
}

function elementScrolled(entry, layers){
    debug("scroll: ");
    debug(entry);
    if(entry.target != undefined && entry.target.id != "footer"){
      parallaxShift(entry.target.getBoundingClientRect().top, layers, false);
    }/* else if(entry.target != undefined){
      parallaxShift(entry.target.getBoundingClientRect().top, layers, true);
    } */
   
}

function isElementInViewport (element, offset) {
  var rect = element.getBoundingClientRect();
  return (
      rect.top >= -offset &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

function createThreshold()
{
  const threshold = [];
  for (let i = 0; i <= 1.0; i += 0.01)
  {
    threshold.push(i);
  }

  return threshold;
}



function parallaxShift(topDistance, layers, negativeMovement)
{
  var depth, i, layer, len, movement, translate3d;
  for (var i = 0, len = layers.length; i < len; i++)
  {
    layer = layers[i];
    depth = layer.getAttribute('data-depth');
    /*  debug("moving layer:");
    debug(layer);  */
    if (negativeMovement)
    {
      movement = -(topDistance * depth);
    } else
    {
      movement = (topDistance * depth);
    }
    if(window.innerWidth < 1025 && window.innerHeight > 1024 && layer.parentElement.id === "footer"){
      translate3d = 'translate3d(0, ' + (movement * 0.15) + 'px, 0)';
    }else{
      translate3d = 'translate3d(0, ' + movement + 'px, 0)';
    }
   
    layer.style['-webkit-transform'] = translate3d;
    layer.style['-moz-transform'] = translate3d;
    layer.style['-ms-transform'] = translate3d;
    layer.style['-o-transform'] = translate3d;
    layer.style.transform = translate3d;
  }
}

if(window.innerWidth > 768){
  SmoothScroll({
    frameRate: 100,
    animationTime: 500,
    stepSize: 40,
    pulseAlgorithm: 1,
    pulseScale: 4,
    pulseNormalize: 1,
    accelerationDelta: 50,
    accelerationMax: 3,
    keyboardSupport: 1,
    arrowScroll: 50,
    fixedBackground: 0
  });
}

function initDesktopContactForm()
{
  debug("initDesktopContactForm");
  var contactForm = document.getElementById("contact-form");
  var formFeedbackP = document.getElementById("form-feedback");
  var formOverlay = document.getElementById("form-overlay");

  debug(contactForm);
  debug(formFeedbackP);
  debug(formOverlay);

   /* = document.getElementById("form-feedback"); */
  contactForm.addEventListener("submit", function (event)
  {
    //show spinner
    formOverlay.style.visibility = "visible";
    formOverlay.style.display = "block";

    event.preventDefault();

    grecaptcha.ready(function ()
    {
      grecaptcha.execute('6Leya60iAAAAALpr8R51tmCoRvRMdoN4upwUmW56', { action: 'contact_bandc' }).then(function (token)
      {
        if(document.getElementById("contact-token") != undefined){
          document.getElementById("contact-token").remove();
          document.getElementById("contact-action").remove();
        }
        contactForm.insertAdjacentHTML('afterbegin','<input type="hidden" id="contact-token" name="token" value="' + token + '">');
        contactForm.insertAdjacentHTML('afterbegin','<input type="hidden" id="contact-action" name="action" value="contact_bandc">');
        var name = removeTags(document.getElementById("contact-name").value);
        var email = removeTags(document.getElementById("contact-email").value);
        var message = document.getElementById("contact-message").value;
        var action = document.getElementById("contact-action").value;
        
        // Add your logic to submit to your backend server here.
        var request = new XMLHttpRequest();
        var url = "./form-action.php";
        request.open("POST", url, true);
        request.setRequestHeader("Content-Type", "application/json");
        request.onreadystatechange = function ()
        {
          if (request.readyState === 4 && request.status === 200)
          {
              //hide spinner
              formOverlay.style.visibility = "hidden";
              formOverlay.style.display = "none";
            var response = JSON.parse(request.response);
            if (response.type == "success")
            {
              formFeedbackP.classList.remove("w3-red");
              formFeedbackP.classList.add("w3-green");
              contactForm.reset();
              debug(response);
            } else if (response.type == "error")
            {
              formFeedbackP.classList.add("w3-red");
              formFeedbackP.classList.remove("w3-green");
              debug(response);
            }
            formFeedbackP.textContent = removeTags(response.text);
          }
        };

        var hasErrors = true;

        if (!isEmpty(name) && !isEmpty(email) && !isEmpty(message) && !isEmpty(action))
        {
          hasErrors = false;
        }
        var data = JSON.stringify({ "name": name, "email": email, "message": message, "token": token, "action": action });
        if (!hasErrors)
        {
          request.send(data);
        } else
        {
          formFeedbackP.classList.add("w3-red");
          formFeedbackP.classList.remove("w3-green");
          formFeedbackP.textContent = "All inputs are required. Please check and try again.";
          formOverlay.style.visibility = "hidden";
          formOverlay.style.display = "none";
        }
      });
    });
  });
}

function initMobileContactForm()
{
  debug("initMobileContactForm");
  var contactForm = document.getElementById("contact-form-mobile");
  var formFeedbackP = document.getElementById("form-feedback-mobile");
  var formOverlay = document.getElementById("form-overlay-mobile");

  debug(contactForm);
  debug(formFeedbackP);
  debug(formOverlay);

   /* = document.getElementById("form-feedback"); */
  contactForm.addEventListener("submit", function (event)
  {
    //show spinner
    formOverlay.style.visibility = "visible";
    formOverlay.style.display = "block";

    event.preventDefault();

    grecaptcha.ready(function ()
    {
      grecaptcha.execute('6Leya60iAAAAALpr8R51tmCoRvRMdoN4upwUmW56', { action: 'contact_bandc' }).then(function (token)
      {
        if(document.getElementById("contact-mobile-token") != undefined){
          document.getElementById("contact-mobile-token").remove();
          document.getElementById("contact-mobile-action").remove();
        }
        contactForm.insertAdjacentHTML('afterbegin','<input type="hidden" id="contact-mobile-token" name="mobile-token" value="' + token + '">');
        contactForm.insertAdjacentHTML('afterbegin','<input type="hidden" id="contact-mobile-action" name="mobile-action" value="contact_bandc">');
        var name = removeTags(document.getElementById("contact-mobile-name").value);
        var email = removeTags(document.getElementById("contact-mobile-email").value);
        var message = document.getElementById("contact-mobile-message").value;
        var action = removeTags(document.getElementById("contact-mobile-action").value);
        
        // Add your logic to submit to your backend server here.
        var request = new XMLHttpRequest();
        var url = "./form-action.php";
        request.open("POST", url, true);
        request.setRequestHeader("Content-Type", "application/json");
        request.onreadystatechange = function ()
        {
          if (request.readyState === 4 && request.status === 200)
          {
              //hide spinner
              formOverlay.style.visibility = "hidden";
              formOverlay.style.display = "none";
            var response = JSON.parse(request.response);
            if (response.type == "success")
            {
              formFeedbackP.classList.remove("w3-red");
              formFeedbackP.classList.add("w3-green");
              contactForm.reset();
              debug(response);
            } else if (response.type == "error")
            {
              formFeedbackP.classList.add("w3-red");
              formFeedbackP.classList.remove("w3-green");
              debug(response);
            }
            formFeedbackP.textContent = removeTags(response.text);
          }
        };

        var hasErrors = true;

        if (!isEmpty(name) && !isEmpty(email) && !isEmpty(message) && !isEmpty(action))
        {
          hasErrors = false;
        }
        var data = JSON.stringify({ "name": name, "email": email, "message": message, "token": token, "action": action });
        if (!hasErrors)
        {
          request.send(data);
        } else
        {
          formFeedbackP.classList.add("w3-red");
          formFeedbackP.classList.remove("w3-green");
          formFeedbackP.textContent = "All inputs are required. Please check and try again.";
          formOverlay.style.visibility = "hidden";
          formOverlay.style.display = "none";
        }
      });
    });
  });
}

function isEmpty(input)
{
  var errors = false;
  if (input == "" || input == undefined || input == null)
  {
    errors = true;
  }
  return errors;
}

function removeTags(str)
{
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in 
  // the input string. Replacing the identified 
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}